import ValidatorItem from './ValidatorItem';
import Table from 'react-bootstrap/Table';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    
    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items];
        // if (sortConfig !== null) {
        //     sortableItems.sort((a, b) => {
        //       if (a[sortConfig.key] < b[sortConfig.key]) {
        //         return sortConfig.direction === 'ascending' ? -1 : 1;
        //       }
        //       if (a[sortConfig.key] > b[sortConfig.key]) {
        //         return sortConfig.direction === 'ascending' ? 1 : -1;
        //       }
        //       return 0;
        //     });
        //   }
        //   return sortableItems;
        if (sortConfig !== null) {
            if (sortConfig.key === 'apy_estimate') {
                sortableItems.sort((a, b) => (b.apy_estimate === a.apy_estimate ?
                    b.epoch_credits - a.epoch_credits :
                    b.apy_estimate - a.apy_estimate));
            }
            else if (sortConfig.key === 'jito_apy') {
                sortableItems.sort((a, b) => (b.jito_apy === a.jito_apy ?
                    b.epoch_credits - a.epoch_credits :
                    b.jito_apy - a.jito_apy));
            }
            else if (sortConfig.key === 'net_apy') {
                sortableItems.sort((a, b) => (b.staking_apy + b.jito_apy === a.jito_apy + a.staking_apy ?
                    b.epoch_credits - a.epoch_credits :
                    b.staking_apy + b.jito_apy - (a.jito_apy + a.staking_apy)));
            }
            else if (sortConfig.key === 'apy_hist') {
                sortableItems.sort((a, b) => (b.apy_5 === a.apy_5 ?
                    b.epoch_credits - a.epoch_credits :
                    b.apy_5 - a.apy_5));
            }
            else if (sortConfig.key === 'commission') {
                sortableItems.sort((a, b) =>
                
                (b.commission === a.commission ?
                    b.epoch_credits - a.epoch_credits :
                    a.commission - b.commission)
                
                );

            }
            else if (sortConfig.key === 'net_stake_change') {
                sortableItems.sort((a, b) => {
                  
                    if (a.activating_stake - a.deactivating_stake < b.activating_stake - b.deactivating_stake) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a.activating_stake - a.deactivating_stake > b.activating_stake - b.deactivating_stake) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                                                       
                
                });

            }
            else if (sortConfig.key === 'name') {
                sortableItems.sort((a, b) => {
                    if (!(a.name)) {
                        return 1;
                    }
                    else if (!(b.name)) {
                        return -1;
                    }
                    if ((a.name.localeCompare(b.name)) > 0)
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    if ((a.name.localeCompare(b.name)) < 0)
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                  
                    return 0;
                                                       
                
                });

            }
            else {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return b.epoch_credits - a.epoch_credits;
                    
                });                
            }
            
        }
        return sortableItems;        
    }, [items, sortConfig]);
        
    const requestSort = key => {
        let direction = 'descending';
        if (key === 'apy_estimate')
            direction = 'descending';
        else if (key === 'apy_hist')
            direction = 'descending';
        else if (key === 'name') {
            direction = 'ascending';
            if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {                
                direction = 'descending';                
            }
        }
        else if (key === 'commission') {
            direction = 'ascending';
            // if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            //     direction = 'descending';
            // }
        }
        else {        
            if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {                
                direction = 'ascending';                
            }
        }
        setSortConfig({ key, direction });
      }
    
      return { items: sortedItems, requestSort, sortConfig };
    }


const ValidatorTable = (props) => {
    // const { validators } = props;    
    const { items, requestSort, sortConfig } = useSortableData(props.validators);
    // const getClassNamesFor = (name) => {
    //     if (!sortConfig)
    //         return 'notSortConfig';
    //     else {
    //         return 'SORTED';
    //     }
    //   };
    
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return "fa fa-sort";
        }

        var ans = sortConfig.key === name ? sortConfig.direction : "fa fa-sort";
        if (ans === 'descending')
            return "fa fa-sort-desc";
        if (ans === 'ascending')
            return "fa fa-sort-asc";
        return ans;
      };
    return (
        <Table name="top-validators" size="sm" key='validatorTable' light="true"  striped="true" >
        <thead id='validatorTableHead'>
            <tr key='header'>
                <th key='rank'><div>Rank&nbsp;</div></th> 
                <th>
                    <OverlayTrigger
                        key='netapytrig'
                        placement='top'
                        overlay={
                         
                            <Popover id={`tooltip-netapy`} className="text-center"
                                style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                                The Validator's Historical Gross APY = Historical Staking APY + Historical Jito APY.
                            </Popover>
                        }
                        >
                       <div className="table-col-name" onClick={() => requestSort('net_apy')} >
                            Gross APY&nbsp;
                            <i className={getClassNamesFor('net_apy')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                        
                </th>                   
                
               
                
                <th>
                    <div>
                    <div className="table-col-name" onClick={() => requestSort('name')} >
                        Validator&nbsp;
                        <i className={getClassNamesFor('name')} aria-hidden="true"></i>
                    </div>
                        
                        
                        
                    </div>
                </th>
                <th>
                    <OverlayTrigger
                        key='apytrig'
                        placement='top'
                        overlay={
                         
                            <Popover id={`tooltip-apy`} className="text-center"
                                style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                                The Validator's estimated Staking APY for the current epoch.
                            </Popover>
                        }
                        >
                       <div className="table-col-name" onClick={() => requestSort('apy_estimate')} >
                            Staking APY&nbsp;
                            <i className={getClassNamesFor('apy_estimate')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                        
                </th>
                <th>
                    <OverlayTrigger
                        key='jitoapytrig'
                        placement='top'
                        overlay={
                         
                            <Popover id={`tooltip-jitoapy`} className="text-center"
                                style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                                The Validator's historical Jito APY.
                            </Popover>
                        }
                        >
                       <div className="table-col-name" onClick={() => requestSort('jito_apy')} >
                            Jito APY&nbsp;
                            <i className={getClassNamesFor('jito_apy')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                        
                </th>
                
                {/* <th>
                    <OverlayTrigger
                        key='histapytrig'
                        placement='top'
                        overlay={
                            <Popover id={`tooltip-apy`} className="text-center"
                                style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                                The Validator's average APY over the previous 5 epochs.
                            </Popover>
                        }
                        >
                       <div className="table-col-name" onClick={() => requestSort('apy_hist')} >
                            Historic&nbsp;
                            <i className={getClassNamesFor('apy_hist')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                        
                </th> */}
                <th>
                    <OverlayTrigger
                        key='feetrig'
                        placement='top'
                        position='absolute'
                        overlay={
                            <Popover id='tooltip-fee' className="text-center" style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                            The amount of commission the validator charges.
                            </Popover>
                        }
                        >
                        <div className="table-col-name" onClick={() => requestSort('commission')} >
                            Fee &nbsp;
                            <i className={getClassNamesFor('commission')} aria-hidden="true"></i>                                                
                        </div>
                       
                    </OverlayTrigger>
                        
                    </th>
                    <th>
                    <OverlayTrigger
                        key='staketrig'
                        placement='top'
                            overlay={
                            <Popover id={`tooltip-stake`} className="text-center" style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                           
                                Amount of active stake the validator has
                            </Popover>
                        }
                        >
                        <div  className="table-col-name" onClick={() => requestSort('activated_stake')}>
                            Stake&nbsp;
                            <i className={getClassNamesFor('activated_stake')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                    </th>
                    <th>
                    <OverlayTrigger
                        key='chg_staketrig'
                        placement='top'
                            overlay={
                            <Popover id={`tooltip-stakeChng`} className="text-center" style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                        
                            Net Stake change this Epoch.
                            </Popover>
                        }
                        >
                        <div  className="table-col-name" onClick={() => requestSort('net_stake_change')}>
                            Stake Change&nbsp;
                            <i className={getClassNamesFor('net_stake_change')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>
                        
                    </th>
                    <th>
                    <OverlayTrigger
                        key='skipratetrig'
                        placement='top'
                            overlay={
                            <Popover id={`tooltip-skip-rate`} className="text-center" style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>                                                   
                                The percentage of this validator's leader slots that it has failed to make blocks on.
                            </Popover>
                        }
                        >
                        <div className="table-col-name">
                            Skip Rate&nbsp;                            
                        </div>
                       
                    </OverlayTrigger>


                        
                    </th>
                    <th>
                    <OverlayTrigger
                        key='asncitytrig'
                        placement='top'
                            overlay={
                            <Popover id={`tooltip-asncity`} className="text-center" style={{ paddingLeft: "0.3rem",paddingRight: "0.3rem",paddingTop: "0.2rem",paddingBottom: "0.2rem", backgroundColor: "black", color: "white" }}>
                                                   
                                Stake concentration of the Datacenter where the validator sits.
                            </Popover>
                        }
                        >
                        <div className="table-col-name"  onClick={() => requestSort('asncity_concentration')}>
                            Datacenter&nbsp;
                            <i className={getClassNamesFor('asncity_concentration')} aria-hidden="true"></i>
                        </div>
                       
                    </OverlayTrigger>


                        
                    </th>

                
        
            </tr>
        </thead>        
        <tbody id= 'validatorTableBody' key='validatorTableBody'>          
                {items.map((data, i) => (
                    
                <ValidatorItem key={['I_' + i]} validator={data} index={i+1} />
            ))}
          </tbody>            
        </Table>
    );
}
export default ValidatorTable